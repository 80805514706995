<template>
  <div class="categorization-selector">
    <div class="categorization-selector__search">
      <md-field>
        <md-input
          v-model="query"
          :placeholder="$translate('products.categories.search')"
          @input="() => search_categories(query)"
        />
      </md-field>

      <div
        v-if="found_categories.length || loading_search"
        class="categorization-selector__search-items"
        v-click-outside="() => found_categories = []"
      >
        <div v-if="!loading_search">
          <div
            v-for="{ name, categories, has_children } in found_categories"
            :key="name"
            @click="select_searched_item(categories, has_children)"
            v-html="assemble_categories(categories, name)"
            class="categorization-selector__search-item"
          />
        </div>
        <div v-else>
          <loader class="categorization-selector__loader" :size="loader_sizes.small"/>
        </div>
      </div>
    </div>

    <md-tabs :md-active-tab="active_tab" @md-changed="id => active_tab = id">
      <md-tab id="base" :md-label="$translate('products.categories.base_categories')">
        <loader v-if="loading" class="categorization-selector__loader" :size="loader_sizes.small" />
        <md-list v-else class="categorization-selector__list">
          <md-list-item
            v-for="category in loaded_categories.base"
            :key="category.id"
            @click="get_categories(category, 0)"
          >{{ category.name }} ></md-list-item>
        </md-list>
      </md-tab>
      <md-tab
        v-for="({ id, name }, index) in selected_categories"
        :key="id"
        :id="id"
        :md-label="name"
      >
        <loader v-if="loading" class="categorization-selector__loader" :size="loader_sizes.small" />
        <md-list v-else class="categorization-selector__list">
          <md-list-item
            v-for="category in loaded_categories[id]"
            :key="category.id"
            @click="get_categories(category, index + 1)"
          >{{ category.name }} ></md-list-item>
        </md-list>
      </md-tab>
    </md-tabs>
  </div>
</template>

<script>
import loader from "@/applications/Shared/components/loader/index.vue"
import { loader_sizes } from "@/applications/Shared/components/loader"
import debounce from "../../../../Shared/methods/debounce"
import { get_product_categories, search_categories } from "../../../constants/endpoints/other"

export default {
  components: {
    loader,
  },
  data() {
    return {
      loading: false,
      loading_search: false,
      selected_categories: [],
      loaded_categories: {},
      found_categories: [],
      active_tab: "base",
      query: "",
      loader_sizes
    }
  },
  mounted() {
    this.get_categories()
  },
  methods: {
    async search_categories(query) {
      debounce(async () => {
        if (query.length > 2) {
          this.loading_search = true
          const { data } = await search_categories({ query })
          this.found_categories = data
          this.loading_search = false
        }
      }, 1000, this)()
    },
    async get_categories(value, index) {
      if (value) {
        this.selected_categories[index] = value
        this.selected_categories.length = index + 1
      }

      if (!value || value.has_children) {
        this.loading = true

        const category_ids = this.selected_categories.length ? this.selected_categories.map(({ id }) => id) : undefined
        const { data } = await get_product_categories({ category_ids })

        this.loaded_categories = {
          ...this.loaded_categories,
          [value ? value.id : "base"]: data
        }
        this.active_tab = value ? value.id : "base"

        this.loading = false
      } else this.$emit("selected_categories", this.selected_categories.map(({ id }) => id))
    },
    assemble_categories(categories, final_category_name) {
      const correct_categories_length = categories.length - 1 // To exclude the searched one
      const correct_initial_categories = correct_categories_length > 3 ? "... > " : ""
      const correct_middle_categories = categories
        .map(({ name }) => name)
        .splice(
          correct_categories_length > 3 ? correct_categories_length - 2 : 0,
          correct_categories_length > 3 ? correct_categories_length - 3 : correct_categories_length - 1
        )
        .join(" > ")

      return `
          <span>
            ${correct_initial_categories}
            ${correct_middle_categories}
            ${correct_middle_categories.length ? " > " : ""}
          </span>
          <b>${final_category_name}</b>
        `
    },
    select_searched_item(categories, has_children) {
      this.found_categories = []

      if (!has_children) return this.$emit("selected_categories", categories.map(({ id }) => id))

      const load_categories = async (promise, index) => {
        await promise

        if (index === categories.length - 1) return

        load_categories(this.get_categories({ ...categories[index + 1], has_children: true }, index + 1), index + 1)
      };

      load_categories(this.get_categories({ ...categories[0], has_children: true }, 0), 0)
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/_global-constants" as *;

  .categorization-selector {
    &__list {
      max-height: 300px;
      overflow: auto;
    }

    &__loader {
      margin: $double-default-size auto;
      justify-content: center;
    }

    &__search {
      position: relative;

      .md-field {
        display: block;
        margin: 0 auto $half-default-size;
        padding: $material-size $half-default-size 0;
        width: calc(100% - #{$material-size * 2});

        input {
          text-align: center;
        }
      }

      &-items {
        position: absolute;
        left: $half-default-size;
        right: $half-default-size;
        top: 100%;
        z-index: 10;
        background-color: $pure-white;
        max-height: 200px;
        overflow: auto;
        box-shadow: 0px 20px 20px -2px $shadow-color;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;

        span {
          font-size: 12px;
        }
      }

      &-item {
        padding: $half-default-size;
        border-bottom: 1px solid $material-grey--light;
        transition: $material-transition;
        cursor: pointer;

        &:hover {
          background-color: $blue;
          color: $pure-white;
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
</style>

<template>
  <section class="cvs-product-upload">
    <md-button class="md-icon-button md-raised md-normal" @click="toggle_csv_upload = true">
      <md-icon>upload_file</md-icon>
      <md-tooltip :md-direction="right_tooltip ? 'right' : 'top'">{{ $translate("products.csv_upload.tooltip") }}</md-tooltip>
    </md-button>

    <md-dialog :md-active.sync="toggle_csv_upload">
      <md-dialog-content class="cvs-product-upload__modal">
        <h2 class="md-title">{{ $translate("products.csv_upload.title") }}</h2>
        <div class="md-body-2">{{ $translate("products.csv_upload.message") }}</div>
        <div class="md-body-1" v-html="$translate('products.csv_upload.template_download')"/>

        <div class="md-elevation-5 file-upload">
          <input
            type="file"
            id="csv-upload"
            accept=".csv"
            @drop="event => add_csv_file(event.target.files)"
            @input="event => add_csv_file(event.target.files)"
            v-cloak
          >
          <label for="csv-upload">
            <md-icon>cloud_upload</md-icon>
            <span>{{ $translate("products.csv_upload.drag_and_drop_csv") }}</span>
          </label>
        </div>

        <md-list>
          <md-list-item md-expand>
            <md-icon>help</md-icon>
            <div class="md-list-item-text"><span class="md-body-2">{{ $translate("products.csv_upload.table_title") }}</span></div>

            <md-list slot="md-expand">
              <md-table v-model="cvs_options" md-model-id="key">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                  <md-table-cell :md-label="$translate('products.csv_upload.column')" v-html="item.title" />
                  <md-table-cell :md-label="$translate('products.csv_upload.explanation')" v-html="item.message" />
                </md-table-row>
              </md-table>
            </md-list>
          </md-list-item>
        </md-list>

        <div v-if="loading_csv" class="admin-loader">
          <Loader />
        </div>
      </md-dialog-content>
    </md-dialog>
  </section>
</template>

<script>
import { mapMutations, mapState } from "vuex"
import Loader from "@/applications/Shared/components/loader/index.vue"
import { ADMIN_STORE, PRODUCTS_STORE } from "../../../constants/others_constants"
import { csv_fields } from "../../../constants/csv_import_constants"
import create_product_from_csv from "../../../methods/create_product_from_csv"
import { ADD_COLLECTIONS, ADD_PRODUCTS } from "../../../stores/Admin/products/constants"
import { IMPORTED_DATA_UPDATED } from "../../../stores/Admin/constants"

export default {
  components: {Loader},
  props: {
    right_tooltip: {
      default: false,
      type: Boolean,
    }
  },
  data() {
    return {
      toggle_csv_upload: false,
      loading_csv: false,
      uploaded_csv_file: null,
      field_values: csv_fields
    }
  },
  computed: {
    ...mapState(PRODUCTS_STORE, [
      "imported_collections",
      "imported_products",
    ]),
    cvs_options() {
      return Object.keys(this.field_values).map((key) => ({
        key,
        title: this.$translate(`products.csv_upload.fields.${key}`).split(" - ")[0],
        message: this.$translate(`products.csv_upload.fields.${key}`).split(" - ")[1]
      }))
    }
  },
  methods: {
    ...mapMutations(ADMIN_STORE, {
      imported_data_updated: IMPORTED_DATA_UPDATED,
    }),
    ...mapMutations(PRODUCTS_STORE, {
      add_products: ADD_PRODUCTS,
      add_collections: ADD_COLLECTIONS
    }),
    add_csv_file(files) {
      this.loading_csv = true
      Array.from(files).forEach((file) => {
        try {
          const reader = new FileReader()

          reader.addEventListener("load", (e) => this.convert_csv_to_json(e.target.result), false)

          reader.readAsText(file, 'UTF-8')
        } catch (error) {
          console.log(error)
        }
      })
    },
    convert_csv_to_json(csv) {
      let rows = csv
        .split(/\n(?=(?:[^"]|"[^"]*")*$)/g)
        .filter(val => val && val !== "\n")
      let keys = rows.shift().split(",")
      let items = []

      rows.forEach(raw_row => {  
        let row = {}
        let columns = raw_row.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)
        
        columns.forEach((column, index)=>{
          let key = keys[index]

          if (!key) return

          row[key.replaceAll("\r", "")] = column.replaceAll("\"", "").replaceAll("\r", "")
        })

        items.push(row)
      })

      this.create_product_data(items)
    },
    create_product_data(transformed_data) {
      let base_products = transformed_data.map(product => create_product_from_csv(product))
      let base_collections = base_products
        .reduce((total, { collections = [] }) => [...total, ...collections], [])
        .reduce((total, collection) =>
          total.find(({ title }) => title === collection.title) ? total : [...total, collection]
        , [])

      const final_csv_products = base_products.map(product => ({
        ...product,
        ...(product.collections && {
          collections: [base_collections.find(({ title }) => title === product.collections[0].title)]
        })
      }))
      const final_csv_collections = base_collections.map(collection => ({
        ...collection,
        products: final_csv_products
          .filter(({ collections = [] } = {}) => collections?.[0]?.title === collection?.title)
          // eslint-disable-next-line no-unused-vars
          .map(({ collections, ...rest } = {}) => rest)
      }))

      // Only add collections with handle that dont exist yet
      const new_collections = final_csv_collections.reduce((total, collection) =>
        this.imported_collections.find(({ handle }) => handle?.toLowerCase() === collection.handle?.toLowerCase()) ? total : [...total, collection]
      , [])

      // Update existing collection products that have the same handle as new
      const updated_existing_collections = this.imported_collections.map(collection => {
        const same_collection = final_csv_collections.find(({ handle }) => handle?.toLowerCase() === collection.handle?.toLowerCase())

        return same_collection ? {
          ...collection,
          products: [
            ...(collection?.products || []),
            ...(same_collection?.products || [])
          ]
        } : collection
      })

      const final_collections = [...updated_existing_collections, ...new_collections]

      const products_with_updated_collections = final_csv_products.map(product => {
        const updated_collections = (product.collections || []).map(
          ({ handle } = {}) => final_collections.find(collection => handle?.toLowerCase() === collection.handle?.toLowerCase())
        )

        return {
          ...product,
          collections: updated_collections
        }
      })

      const final_products = [...this.imported_products, ...products_with_updated_collections]

      this.add_collections(final_collections)
      this.add_products(final_products)

      setTimeout(() => {
        this.loading_csv = false
        this.toggle_csv_upload = false
      }, 1000);
      this.imported_data_updated(true)
    }
  }
}
</script>

<style lang="scss">
@use "../../../../../styles/_admin-constants" as *;
@use "../../../../../styles/_global-constants" as *;
@use "../../../../../styles/admin_file_upload" as *;

.cvs-product-upload {
  display: inline-block;

  &__modal {
    padding: 0 $default-size;

    .admin-loader {
      position: absolute !important;
      height: 100%;
      top: 0;
      left: 0;

     .loader-wave {
        position: absolute !important;
        top: calc(50% - 20px);
        z-index: 1000;
      }
    }
  }
}
</style>

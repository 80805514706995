<template>
  <div class="image-upload-and-editor">
    <!-- Preview uploaded images -->
    <div
      v-if="!edited_image"
      class="image-upload-and-editor__image-wrapper"
      :class="{ 'image-upload-and-editor__image-wrapper--single': !uploaded_images.length }"
    >
      <!-- Upload images -->
      <div class="image-upload-and-editor__upload">
        <md-icon class="image-upload-and-editor__icon">cloud_upload</md-icon>
        <input
          data-cy="upload"
          type="file"
          id="image-upload"
          accept=".jpg, .jpeg, .png, .webp"
          :multiple="allow_multiple_files ? 'multiple' : ''"
          @drop="event => add_images(event.target.files)"
          @input="event => add_images(event.target.files)"
          v-cloak
        >
      </div>

      <div
        v-for="({ src, id, alt_text }, index) in uploaded_images"
        :key="id"
        class="image-upload-and-editor__image"
      >
        <md-button
          class="image-upload-and-editor__image-delete md-icon-button md-accent md-raised md-dense"
          @click="delete_image(index)"
        >
          <md-icon>delete</md-icon>
        </md-button>

        <md-button
          class="image-upload-and-editor__image-edit md-icon-button md-primary md-raised md-dense"
          @click="edit_image(index)"
        >
          <md-icon>tune</md-icon>
        </md-button>

        <img :src="src" class="md-elevation-3"/>

        <!-- Image alt text input -->
        <md-field>
          <label>{{ $translate('products.images.alt_text') }}:</label>
          <md-input
            :value="alt_text"
            md-autogrow
            :placeholder="$translate('products.images.image_description')"
            @change="({ target }) => uploaded_images[index].alt_text = target.value"
          />
        </md-field>
      </div>
    </div>

    <!-- Editor -->
    <div v-show="edited_image">
      <div class="image-upload-and-editor__editor">
        <PinturaEditor
          v-bind="editorDefaults"
          :src="edited_image"
          @pintura:process="update_edited_image($event)"
        />
      </div>
    </div>

    <div v-if="!edited_image">
      <md-button @click="clear_uploaded_images">{{ $translate("clear_images") }}</md-button>
      <md-button class="md-primary" @click="save_changes" data-cy="finish-upload">{{ $translate("done") }}</md-button>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { getEditorDefaults } from "@pqina/pintura";
import { PinturaEditor } from "@pqina/vue-pintura/vue-2";
import { v4 as uuid } from "uuid"
import sanitize_image_route from "../../methods/sanitize_image_route";
import { created, updated } from "../../constants/others_constants";
import get_image_src from "../../../Shared/methods/get_image_src";
import { image_dimension_names } from "../../../../data/other_constants";

export default {
  components: {
    PinturaEditor,
  },
  props: {
    image: {
      type: Object,
      required: false,
      default: null
    },
    entity_title: {
      type: String,
      required: true
    },
    allow_multiple_files: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      edited_image_index: null,
      edited_image: null,
      uploaded_images: [],
      editorDefaults: getEditorDefaults(),
      edited_alt_text: null,
    };
  },
  beforeMount() {
    if (this.image) this.edited_image = get_image_src(this.image, image_dimension_names.desktop)
  },
  methods: {
    add_images(files) {
      Array.from(files).forEach((file) => this.uploaded_images = [
        {
          src: URL.createObjectURL(file),
          id: uuid(),
          file,
          change_type: created,
          route: sanitize_image_route(this.entity_title)
        },
        ...this.uploaded_images
      ])
    },
    async save_images() {
      this.images = [
        ...this.images,
        ...this.uploaded_images.map(image => ({ ...image, alt_text: this.image_alt_text || this.entity_title }))
      ]

      this.image_alt_text = null
      this.uploading_image = false
      this.uploaded_images = []
    },
    update_edited_image({ dest: file }) {
      if (this.image) {
        this.$emit("save", {
          ...this.image,
          file,
          change_type: this.image.change_type || updated,
          src: URL.createObjectURL(file),
          route: sanitize_image_route(this.entity_title),
          alt_text: this.edited_alt_text || this.image.alt_text || this.entity_title
        })
      }
      else {
        Vue.set(this.uploaded_images[this.edited_image_index], "src", URL.createObjectURL(file))
        Vue.set(this.uploaded_images[this.edited_image_index], "file", file)
      }

      this.edited_image_index = null
      this.edited_image = null
    },
    delete_image(index) {
      this.uploaded_images.splice(index, 1)
    },
    edit_image(index) {
      this.edited_image = this.uploaded_images[index].src
      this.edited_image_index = index
    },
    save_changes() {
      this.$emit("save", this.uploaded_images)
      this.edited_image = null
      this.edited_image_index = null
    },
    clear_uploaded_images() {
      this.edited_image = null
      this.edited_image_index = null
      this.uploaded_images = []
    },
  },
};
</script>

<style lang="scss">
@use "../../../../styles/_global-constants" as *;
@use "../../../../styles/_admin-constants" as *;
@import "../../../../../node_modules/@pqina/pintura/pintura.css";

.image-upload-and-editor {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  &__upload {
    position: relative;
    width: 64px;
    height: 64px;
    background-color: $blue;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin: $double-default-size auto;

    &:hover {
      background-color: lighten($blue, 10%);
      transform: scale(1.1);
    }

    &:active {
      background-color: darken($blue, 10%);
      transform: scale(0.9);
    }

    input[type='file'] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;

      &:focus + .image-upload-and-editor__icon {
        color: $blue--darker;
        transform: scale(1.2) rotate(180deg);
      }
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $pure-white;
    transition: all 0.2s ease-in-out;
  }

  &__editor {
    height: 500px;
    min-width: 800px;
    width: 100%;

    &-alt-input {
      margin: 0 auto;
      padding: 0 $default-size $default-size;
    }

    @media (max-width: $tablet) {
      min-width: auto;
      height: 500px;
      width: 80vw;
    }

    @media (max-width: $mobile) {
      width: 90vw;
      height: 400px;
    }
  }

  &__image {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    margin: $half-default-size;

    img {
      width: 100%;
      max-width: 150px;
      height: auto;
      max-height: 150px;
      border-radius: $border-radius;
      object-fit: contain;
    }

    .md-field {
      margin-bottom: 0;
    }

    &-delete, &-edit {
      position: absolute !important;
      top: 5px;
      left: 5px;
      margin: 0 !important;
    }

    &-edit {
      left: auto;
      right: 5px;
    }

    &-wrapper {
      display: grid;
      grid-template-columns: repeat(3, minmax(150px, 1fr));

      &--single {
        grid-template-columns: repeat(1, minmax(150px, 1fr)) !important;
      }

      @media (max-width: $tablet--small) {
        grid-template-columns: repeat(2, minmax(150px, 1fr));
      }
    }
  }
}
</style>
